import ShipmentRole from 'shared/models/ShipmentRole.model'
import { Cargo } from 'components_sites_calendar/store/interfaces/eventTypes'
import AttachedDocument from 'shared/models/AttachedDocument.model'
import { ID, LOOSE_ID } from 'interfaces/generalTypes'
import { Deletable } from 'shared/util/types.util'
import { v1 as uuidv1 } from 'uuid'
import { Order } from './Order.model'

export default interface Shipment<CargoType extends Partial<Cargo> = Cargo> extends Deletable {
  id?: ID
  tradelinkId?: string
  referenceNumber?: string
  referenceNumberConflict?: boolean
  cargoes: CargoType[]
  documents: AttachedDocument[]
  shipmentRoles: ShipmentRole[]
  frontendId?: LOOSE_ID
  orders: Order[]
  invalidOrderNumbers: string[]
}

export function buildEmptyShipment(withFrontendId?: boolean): Shipment {
  const attrs: Shipment = {
    cargoes: [],
    documents: [],
    shipmentRoles: [],
    orders: [],
    invalidOrderNumbers: [],
  }

  if (withFrontendId) attrs.frontendId = uuidv1()

  return attrs
}
