// eslint-disable-next-line import/prefer-default-export
export enum AdviceStatus {
  Accepted = 'accepted',
  Archived = 'archived',
  Booked = 'booked',
  Completed = 'completed',
  Declined = 'declined',
  NoShow = 'no_show',
  Noted = 'noted',
  Requested = 'requested',
  Reserved = 'reserved',
  Suggested = 'suggested',
  Withdrawn = 'withdrawn',
  Rejected = 'rejected',
}
